<template>
  <div class="authors-page px-2">
    <div class="px-2 title font-weight-bold h5 my-4 mt-2">المتحدثون</div>
    
    <div class="hide-scrollbar ps-4" v-if="$apollo.queries.authors.loading">
        <HorizontalProgramCardLoader />
    </div>

    <div class="row justify-content-md-center text-center text-light mx-0">
        <div class="" v-for="author in authors" :key="author.id">
            <AuthorHorizontalCard :author="author" />
        </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import HorizontalProgramCardLoader from "../../components/Loader/HorizontalProgramCardLoader.vue";
import AuthorHorizontalCard from "../../components/AuthorHorizontalCard.vue";

export default {
  components: { HorizontalProgramCardLoader, AuthorHorizontalCard },

  apollo: {
    authors: {
      query: gql`
        query  {
          authors {
            id
            name_ar
            name_en
            thumbnail_url

            tracksCount
            programsCount
          }
        }
      `,
    },
  },
  data() {
    return {
      authors: [],
    }
  },
};
</script>

<style>

</style>
